import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  AddNewButton,
  Card,
} from '@makeably/creativex-design-system';
import {
  emptyState,
  filterProps,
  getNullableDimension,
  renderDeleteButton,
} from 'components/internal/shared';
import FilterTags from 'components/molecules/FilterTags';
import ItemsFilter from 'components/molecules/ItemsFilter';
import ItemsTable from 'components/molecules/ItemsTable';
import {
  addToast,
  addErrorToast,
} from 'components/organisms/Toasts';
import { saveFilterQuery } from 'utilities/filtering';
import { getItemSortBy } from 'utilities/item';
import {
  filterItems,
  getValueOptions,
} from 'utilities/itemFilter';
import { destroy } from 'utilities/requests';
import {
  internalReviewGuideSubscriptionPath,
  newInternalReviewGuideSubscriptionPath,
} from 'utilities/routes';
import { toDate } from 'utilities/string';
import {
  getPage,
  getParams,
  setParam,
} from 'utilities/url';

const subscriptionProps = PropTypes.shape({
  category: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  guidelineName: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  updatedAt: PropTypes.string.isRequired,
  definitionName: PropTypes.string,
});

const propTypes = {
  reviewGuideId: PropTypes.number.isRequired,
  reviewGuideTitle: PropTypes.string.isRequired,
  subscriptions: PropTypes.arrayOf(subscriptionProps).isRequired,
  initialFilterSelections: filterProps,
};

const defaultProps = {
  initialFilterSelections: {},
};

const filterDimensions = [
  {
    label: 'Company',
    value: 'company',
  },
  {
    label: 'Guideline Name',
    value: 'guidelineName',
  },
  {
    label: 'Type',
    value: 'category',
  },
];

const headers = [
  {
    key: 'id',
    label: 'ID',
  },
  {
    label: 'Company',
    key: 'company',
  },
  {
    label: 'Type',
    key: 'category',
  },
  {
    key: 'guidelineId',
    label: 'Guideline\nID',
  },
  {
    key: 'guidelineName',
    label: 'Guideline\nName',
  },
  {
    key: 'definitionId',
    label: 'Definition\nID',
  },
  {
    key: 'definitionName',
    label: 'Definition\nName',
  },
  {
    key: 'updatedAt',
    label: 'Last Updated',
  },
  {
    key: 'remove',
    label: '',
    sortable: false,
  },
];

function getItems(subscriptions, handleDelete) {
  return subscriptions.map(({
    category,
    company,
    definitionId,
    definitionName,
    guidelineId,
    guidelineName,
    id,
    updatedAt,
  }) => ({
    category: { value: category },
    company: { value: company },
    definitionId: getNullableDimension(definitionId),
    definitionName: getNullableDimension(definitionName),
    guidelineId: { value: guidelineId },
    guidelineName: { value: guidelineName },
    id: { value: id },
    remove: {
      element: renderDeleteButton(() => handleDelete(id)),
      value: id,
    },
    updatedAt: {
      label: toDate(new Date(updatedAt)),
      value: updatedAt,
    },
  }));
}

function ReviewGuideSubscriptions({
  initialFilterSelections,
  reviewGuideId,
  reviewGuideTitle,
  subscriptions,
}) {
  const params = getParams(window);
  const [items, setItems] = useState([]);
  const [filterOpen, setFilterOpen] = useState(false);
  const [filterOptions, setFilterOptions] = useState({});
  const [filterSelections, setFilterSelections] = useState(initialFilterSelections);
  const [filteredItems, setFilteredItems] = useState([]);
  const [sort, setSort] = useState({
    key: 'updatedAt',
    asc: false,
  });
  const [sortedItems, setSortedItems] = useState([]);
  const [page, setPage] = useState(getPage(params));

  const handleDelete = async (id) => {
    const { isError } = await destroy(internalReviewGuideSubscriptionPath(reviewGuideId, id));

    if (isError) {
      addErrorToast('Could not delete subscription. Please try again later');
    } else {
      addToast('Subscription deleted');
      window.location.reload();
    }
  };

  useEffect(() => {
    const allItems = getItems(subscriptions, handleDelete);
    const options = getValueOptions(filterDimensions, allItems);

    setItems(allItems);
    setFilterOptions(options);
  }, [subscriptions]);

  useEffect(() => {
    setFilteredItems(filterItems(items, filterSelections));
  }, [items, filterSelections]);

  const handleFilterSelect = async (value) => {
    setPage(1);
    setFilterSelections(value);

    if (Object.keys(value).length > 0) {
      const uuid = await saveFilterQuery(value);
      setParam('filter_uuid', uuid, params, window);
    } else {
      setParam('filter_uuid', '', params, window);
    }
  };

  useEffect(() => {
    const byKeyDir = getItemSortBy(sort.key, sort.asc);
    setSortedItems(filteredItems.slice().sort(byKeyDir));
  }, [filteredItems, sort]);

  return (
    <Card className="u-flexColumn u-gap-24">
      <h5>
        { `Subscriptions for '${reviewGuideTitle}'` }
      </h5>
      <div className="u-flexRow u-justifyBetween u-gap-8">
        <div className="u-flexRow u-alignCenter u-gap-8">
          <ItemsFilter
            dimensions={filterDimensions}
            isOpen={filterOpen}
            options={filterOptions}
            selections={filterSelections}
            onClose={() => setFilterOpen(false)}
            onOpen={() => setFilterOpen(true)}
            onSelect={handleFilterSelect}
          />
          <FilterTags
            dimensions={filterDimensions}
            selections={filterSelections}
            onClick={() => setFilterOpen(true)}
            onRemove={handleFilterSelect}
          />
        </div>
        <AddNewButton
          label="Add Subscriptions"
          url={newInternalReviewGuideSubscriptionPath(reviewGuideId)}
        />
      </div>
      <ItemsTable
        className="x-scroll-shadows"
        emptyTableContent={emptyState}
        headers={headers}
        items={sortedItems}
        page={page}
        sort={sort}
        onPageChange={setPage}
        onSortChange={setSort}
      />
    </Card>
  );
}

ReviewGuideSubscriptions.propTypes = propTypes;
ReviewGuideSubscriptions.defaultProps = defaultProps;

export default ReviewGuideSubscriptions;
