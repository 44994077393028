import React from 'react';
import { Tooltip } from '@makeably/creativex-design-system';
import { NULL_VALUE } from 'components/internal/shared';
import { valueIf } from 'utilities/object';
import {
  internalExplorerAssetPath,
  internalExplorerAuditPath,
  internalExplorerAuditAssetPath,
  internalExplorerAuditPostPath,
  internalExplorerMarketPath,
  internalExplorerOrganizationPath,
  internalExplorerOrganizationBrandPath,
  internalExplorerUserPath,
} from 'utilities/routes';
import {
  toDate,
  toDateTime,
} from 'utilities/string';

function getNullDimension() {
  return {
    label: 'NULL',
    value: NULL_VALUE,
  };
}

function getIdPath(key, value) {
  switch (key) {
    case 'asset_id':
    case 'primary_asset_id':
      return internalExplorerAssetPath(value);
    case 'audit_id':
      return internalExplorerAuditPath(value);
    case 'audit_asset_id':
      return internalExplorerAuditAssetPath(value);
    case 'market_id':
      return internalExplorerMarketPath(value);
    case 'company_id':
    case 'organization_id':
    case 'parent_id':
      return internalExplorerOrganizationPath(value);
    case 'organization_brand_id':
      return internalExplorerOrganizationBrandPath(value);
    case 'primary_audit_post_id':
      return internalExplorerAuditPostPath(value);
    case 'reviewer_id':
    case 'inviter_id':
    case 'user_id':
      return internalExplorerUserPath(value);
    default:
      return null;
  }
}

function formatElement(key, value, type) {
  switch (type) {
    case 'id':
      return <a href={getIdPath(key, value)}>{ value }</a>;
    case 'link':
      return <a href={value}>{ value }</a>;
    default:
      return null;
  }
}

function formatLabel(value, type) {
  switch (type) {
    case 'array':
      return `[${value}]`;
    case 'bool':
      return value ? 'true' : 'false';
    case 'date':
      return toDate(new Date(value), true);
    case 'time':
      return toDateTime(new Date(value));
    default:
      return null;
  }
}

function formatValue(value, type) {
  switch (type) {
    case 'array':
      return `[${value}]`;
    default:
      return value;
  }
}

export function getDimension(key, inputValue, type) {
  if (inputValue === null) return getNullDimension();

  if (type) {
    const element = formatElement(key, inputValue, type);
    const label = formatLabel(inputValue, type);
    const value = formatValue(inputValue, type);

    return {
      ...valueIf(element, 'element', element),
      ...valueIf(label, 'label', label),
      value,
    };
  }

  return { value: inputValue };
}

export function getRecordItem(record, typesByKey = {}) {
  const keys = Object.keys(record).sort();

  return keys.reduce((obj, key) => ({
    ...obj,
    [key]: getDimension(key, record[key], typesByKey[key]),
  }), {});
}

function getResultTypes(type, tooltip) {
  if (tooltip) {
    return (
      <Tooltip label={tooltip}>
        { type }
      </Tooltip>
    );
  }

  return type;
}

export function renderResult({
  id,
  description,
  path,
  tooltip,
  type,
}) {
  return (
    <a
      key={path}
      className="u-flexRow u-gap-8 u-marginBottom-16"
      href={path}
    >
      <div>{ id }</div>
      <div>{ getResultTypes(type, tooltip) }</div>
      <div>{ description && `(${description})` }</div>
    </a>
  );
}
