import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { Card } from '@makeably/creativex-design-system';
import {
  getRecordItem,
  renderResult,
} from 'components/internal/explorer/shared';
import ItemGrid from 'components/molecules/ItemGrid';

export const linksProps = PropTypes.arrayOf(
  PropTypes.shape({
    label: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }),
);

export const relatedProps = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.number.isRequired,
    path: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }),
);

const propTypes = {
  record: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  type: PropTypes.string.isRequired,
  actions: PropTypes.arrayOf(PropTypes.element),
  columnTypes: PropTypes.objectOf(PropTypes.string),
  links: linksProps,
  related: relatedProps,
  subtitle: PropTypes.string,
};

const defaultProps = {
  actions: [],
  columnTypes: {},
  links: [],
  related: [],
  subtitle: undefined,
};

function renderActions(actions, links) {
  if (actions.length === 0 && links.length === 0) return null;

  return (
    <div>
      <h5 className="u-marginBottom-8">Actions</h5>
      <div className="u-flexRow u-gap-16 u-alignCenter">
        { actions }
        { links.map(({ label, url }) => <a key={url} href={url}>{ label }</a>) }
      </div>
    </div>
  );
}

export function renderRelated(results) {
  if (results.length === 0) return null;

  return (
    <div>
      <h5 className="u-marginBottom-8">Related</h5>
      { results.map((result) => renderResult(result)) }
    </div>
  );
}

function RecordDisplay({
  actions,
  links,
  record,
  related,
  subtitle,
  type,
  columnTypes,
}) {
  const [item, setItem] = useState({});

  useEffect(() => {
    setItem(getRecordItem(record, columnTypes));
  }, [record]);

  return (
    <Card className="u-flexColumn u-gap-24">
      <div>
        <h4>{ `${type} ${record.id}` }</h4>
        { subtitle && (
          <div className="u-marginTop-8 u-marginLeft-8">
            { subtitle }
          </div>
        ) }
      </div>
      { renderActions(actions, links) }
      <div>
        <h5 className="u-marginBottom-8">Columns</h5>
        <ItemGrid item={item} />
      </div>
      { renderRelated(related) }
    </Card>
  );
}

RecordDisplay.propTypes = propTypes;
RecordDisplay.defaultProps = defaultProps;

export default RecordDisplay;
