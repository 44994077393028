import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  MessageModal,
} from '@makeably/creativex-design-system';
import RecordDisplay, { relatedProps } from 'components/internal/explorer/RecordDisplay';
import {
  addErrorToast,
  addToast,
} from 'components/organisms/Toasts';
import { destroy } from 'utilities/requests';
import {
  internalExplorerAuditPath,
  internalExplorerSearchPath,
} from 'utilities/routes';

const propTypes = {
  columnTypes: PropTypes.objectOf(PropTypes.string).isRequired,
  record: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  related: relatedProps.isRequired,
  subtitle: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

function getActions({
  id,
  pretest,
}) {
  const [modalShown, setModalShown] = useState(false);

  if (!pretest) return [];

  const deletePreflight = async () => {
    setModalShown(false);

    const { data, isError } = await destroy(internalExplorerAuditPath(id));

    if (isError || !data.success) {
      addErrorToast('Could not delete preflight. Please try again later');
    } else {
      addToast('Preflight deleted');
      window.location.href = internalExplorerSearchPath();
    }
  };

  return [
    <div key="delete">
      <Button
        label="Delete Preflight"
        variant="destructive"
        onClick={() => setModalShown(true)}
      />
      <MessageModal
        actionButtonLabel="Delete"
        actionButtonVariant="destructive"
        isOpen={modalShown}
        title="Delete Preflight"
        onActionButtonClick={() => deletePreflight()} // eslint-disable-line
        onClose={() => setModalShown(false)}
      >
        { `Are you sure you want to delete preflight #${id}?` }
      </MessageModal>
    </div>,
  ];
}

function Audit({
  columnTypes,
  record,
  related,
  subtitle,
  type,
}) {
  return (
    <RecordDisplay
      actions={getActions(record)}
      columnTypes={columnTypes}
      record={record}
      related={related}
      subtitle={subtitle}
      type={type}
    />
  );
}

Audit.propTypes = propTypes;

export default Audit;
