import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  Dropdown,
  Search as DsSearch,
} from '@makeably/creativex-design-system';
import { renderResult } from 'components/internal/explorer/shared';
import { renderBetaTag } from 'components/internal/shared';
import {
  getParams,
  redirectWithParamsObj,
  setParam,
} from 'utilities/url';
import styles from './Search.module.css';

const resultProps = PropTypes.shape({
  id: PropTypes.number.isRequired,
  path: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
});

const optionProps = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
});

const propTypes = {
  input: PropTypes.string.isRequired,
  results: PropTypes.arrayOf(resultProps).isRequired,
  searchByOptions: PropTypes.arrayOf(optionProps).isRequired,
};

const NOTION_REFERENCE = 'https://www.notion.so/creativex/Data-Explorer-111f4499093d80eaa6b7fa4030aec0af';

function Search({
  input,
  results,
  searchByOptions,
}) {
  const params = getParams(window);
  const [search, setSearch] = useState(input);
  const [selectedOption, setSelectedOption] = useState(searchByOptions[0]);

  useEffect(() => {
    const searchBy = params.get('search_by');

    setSelectedOption((last) => {
      const found = searchByOptions.find(({ value }) => value === searchBy);
      return found ?? last;
    });
  }, [params]);

  const handleSearchBy = (option) => {
    setSelectedOption(option);
    setParam('search_by', option.value, params, window);
  };

  const handleSearch = () => {
    const paramsObj = {
      input: search,
      search_by: selectedOption.value,
    };
    redirectWithParamsObj(paramsObj, params, window);
  };

  const handleKeyDown = (key) => {
    if (key === 'Enter') {
      handleSearch();
    }
  };

  const renderResults = () => {
    if (results.length === 0 && input) {
      return <div className="t-empty">No results found</div>;
    }

    return results.map((result) => renderResult(result));
  };

  return (
    <Card className="u-flexColumn u-gap-24">
      <div className="u-flexRow u-alignCenter u-gap-8">
        { renderBetaTag() }
        <a
          href={NOTION_REFERENCE}
          rel="noreferrer"
          target="_blank"
        >
          Questions?
        </a>
      </div>
      <div className="u-flexRow u-alignEnd u-gap-8">
        <div className={styles.dropdown}>
          <Dropdown
            label="Search By"
            menuProps={{ size: 'medium' }}
            options={searchByOptions}
            selected={selectedOption}
            size="medium"
            onChange={handleSearchBy}
          />
        </div>
        <DsSearch
          placeholder="Enter Search"
          size="large"
          value={search}
          onChange={setSearch}
          onKeyDown={handleKeyDown}
        />
        <Button
          disabled={search?.length === 0}
          label="Search"
          onClick={handleSearch}
        />
      </div>
      <div className={styles.results}>
        <div className={`t-overline ${styles.title}`}>Results</div>
        { renderResults() }
      </div>
    </Card>
  );
}

Search.propTypes = propTypes;

export default Search;
